import logo from './logo.svg';
import './App.css';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { useEffect, useState } from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAJWa5DntEKHMUAGPbaTF3m814h5rqQ_UY',
  authDomain: 'test-8309e.firebaseapp.com',
  projectId: 'test-8309e',
  storageBucket: 'test-8309e.appspot.com',
  messagingSenderId: '761874859132',
  appId: '1:761874859132:web:a0e39ae610bd95a4f1f5f9',
  measurementId: 'G-RN6GLXW7WN',
};

function App() {
  const [token, setToken] = useState('');
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    onMessage(messaging, (payload) => {
      new Notification(payload.notification?.title || '', {
        body: payload.notification?.body,
        icon: payload.notification?.image,
      });
    });
    getToken(messaging, {
      vapidKey:
        'BGhXVPiskX7R1EdA2b43yKSFEdJ9cm-iBAygelcbFu-0b2jqyX7q5dkQ2wlpdAMUHsufDxDY6b-OMG_nuiN-79E',
    })
      .then((currentToken) => {
        if (currentToken) {
          setToken(currentToken);
          console.log(currentToken);
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          alert('No registration token available. Request permission to generate one.');
          // ...
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h2>!!VERSION v2!</h2>
        {token}
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
